div.react-datepicker__input-container input {
	display: inline-block;
	padding: 5px 10px;
	border-radius: 4px;


	border: solid 1px silver;
	width: 120%;
}

div.react-datepicker__input-container input.DateRangeStartDate  {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

div.react-datepicker__input-container input.DateRangeEndDate  {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}