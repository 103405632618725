body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:hover {
  cursor: pointer;
}

.caret {
  transition: transform 100ms ease;
}

.rotate-90 {
  transform: rotate(90deg);
}

table.charge-table {
  border-top: none;
}

table.charge-table td {
  padding: .4rem
}

table.charge-table th {
  line-height: 2.5rem;
  padding: 0 0.5rem;
  border-top: none;
}

.loginCard {
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
}

.topbar {
  display: flex;
  flex-flow: row-reverse;
  height: 4em;
  padding: .75em 1em;
  background-color: rgb(36 52 66);
}

.topbar button {
  margin-left: .75em;
}

.forbidden {
  margin-top: 5em;
  text-align: center;
}

div.body {
  margin-top: 56px;
  width: 100%;
  max-width: 100%;
}

div.patient-bar,
div.filter-bar {
  padding: 1em;
  top: 56px;
  border-bottom: solid 1px gray;
  background: white;
  position: fixed;
  width: 100%;
  z-index: 100;
}

div.treatment {
  margin-top: 170px;
}

div.settings-modal > div {
  max-width: 80%;
}